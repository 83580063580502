<template>
  <div>
    <title>404</title>
    <div class="not-found parallax">
      <div class="sky-bg"></div>
      <div class="wave-7"></div>
      <div class="wave-6"></div>
      <a class="wave-island" href="https://zhenfengit.top">
        <img src="http://res.cloudinary.com/andrewhani/image/upload/v1524501929/404/island.svg" alt="Island"/>
      </a>
      <div class="wave-5"></div>
      <div class="wave-lost wrp">
        <span>4</span>
        <span>0</span>
        <span>4</span>
      </div>
      <div class="wave-4"></div>
      <div class="wave-boat">
        <img class="boat" src="http://res.cloudinary.com/andrewhani/image/upload/v1524501894/404/boat.svg" alt="Boat"/>
      </div>
      <div class="wave-3"></div>
      <div class="wave-2"></div>
      <div class="wave-1"></div>
      <div class="wave-message">
        <p>Your're lost</p>
        <p>Click on the island to return</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        dd: this.rest,
      };
    },
    mounted(){
      var parallax = function(e) {
        //   console.log(e);
          var windowWidth = $(window).width();
          
          if (windowWidth < 768) return;
          var halfFieldWidth = $(".parallax").width() / 2,
            halfFieldHeight = $(".parallax").height() / 2,
            fieldPos = $(".parallax").offset(),
            x = e.pageX,
            y = e.pageY - fieldPos.top,
            newX = (x - halfFieldWidth) / 30,
            newY = (y - halfFieldHeight) / 30;
          $('.parallax [class*="wave"]').each(function(index) {
            $(this).css({
              transition: "",
              transform:
                "translate3d(" +
                index * newX +
                "px," +
                index * newY +
                "px,0px)",
            });
          });
        },
        stopParallax = function() {
          $('.parallax [class*="wave"]').css({
            transform: "translate(0px,0px)",
            transition: "all .7s",
          });
          setTimeout(()=>{
               $('.parallax [class*="wave"]').css("transition", "");
          },700)
        //   $timeout(function() {
        //     $('.parallax [class*="wave"]').css("transition", "");
        //   }, 700);
        };
      $(document).ready(function() {
        $(".not-found").on("mousemove", parallax);
        $(".not-found").on("mouseleave", stopParallax);
      });
    },
  };
</script>

<style scoped>
  @import url("./style.css");
  @import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
</style>
